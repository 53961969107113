import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Suspense, lazy, useState, useEffect } from 'react';

// Lazy load components
const Hero = lazy(() => import('./components/Hero'));
const About = lazy(() => import('./components/About')); 
const Projects = lazy(() => import('./components/Projects'));
const Contact = lazy(() => import('./components/Contact'));
const Chatbot = lazy(() => import('./components/Chatbot'));
const Loader = lazy(() => import('./components/Loader'));
const Navbar = lazy(() => import('./components/Navbar'));

// Loading fallback component
const LoadingFallback = () => (
  <div className="h-screen flex items-center justify-center">
    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-green-500"></div>
  </div>
);

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const observer = new PerformanceObserver((list) => {
      const entries = list.getEntries();
      entries.forEach((entry) => {
        if (entry.entryType === 'largest-contentful-paint') {
          // Esperamos al menos 1.5s antes de quitar el loader
          setTimeout(() => {
            setLoading(false);
            observer.disconnect();
          }, 1500);
        }
      });
    });

    observer.observe({ entryTypes: ['largest-contentful-paint'] });

    return () => observer.disconnect();
  }, []);

  // Este useEffect se ejecutará cuando loading sea false
  useEffect(() => {
    if (!loading) {
      // Realiza la petición a /api/init para establecer la cookie HttpOnly
      fetch('/api/init', {
        method: 'GET',
        credentials: 'include'
      })
      .then(response => response.json())
      .then(data => {
        console.log("");
      })
      .catch(err => console.error('Error en init:', err));
    }
  }, [loading]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="App dark">
      <Router>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Suspense fallback={<LoadingFallback />}>
            <Navbar />
          </Suspense>
          <Routes>
            <Route path="/" element={
              <div className="relative">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ staggerChildren: 0.2 }}
                >
                  <Suspense fallback={<LoadingFallback />}>
                    <Hero />
                    <About />
                    <Projects />
                    <Contact />
                    <Chatbot />
                  </Suspense>
                </motion.div>
              </div>
            } />
          </Routes>
        </motion.div>
      </Router>
    </div>
  );
}

export default App;
